<template>
  <div class="pre-onboarding">
    <div class="pre-steps">
      <div class="pre-progress">
        <div class="pre-progress--bar pre-progress--bar-active"></div>
        <div class="pre-progress--bar pre-progress--bar-active"></div>
        <div class="pre-progress--bar"></div>
        <div class="pre-progress--bar"></div>
      </div>

      <div class="pre-step">
        <div class="pre-subhead">
          What paranormal activities do you believe in?
        </div>

        <div class="pre-copy">
          <p>Select all that apply:</p>
        </div>

        <div class="pre-pad"></div>

        <div class="pre-beliefs">
          <div class="row">
            <div
              class="mb-3"
              :class="{ 'col-12': item.fullWidth, 'col-6': !item.fullWidth }"
              v-for="(item, idx) in activities"
              :key="idx"
            >
              <pnw-button
                :variant="
                  selectedActivities.includes(item.title)
                    ? 'primary'
                    : 'outline-primary'
                "
                class="w-100"
                @click="toggleItem(item)"
              >
                {{ item.title }}</pnw-button
              >
            </div>
          </div>
        </div>
      </div>

      <div class="pre-btns">
        <span class="pre-btn pre-btn-underline mr-4" @click="skipToNextPage">
          <div class="pre-btn--copy">Skip</div>
        </span>
        <pnw-button variant="primary" @click="gotoNextPage" size="xl">
          <div class="pre-btn--copy">Next</div>
        </pnw-button>
      </div>
    </div>
    <div class="pre-display">
      <div class="pre-belief">
        <img
          class="pre-belief--img"
          :src="
            selectedItem
              ? selectedItem.image
              : '/assets/images/quiz/audiowave.png'
          "
          alt=""
        />
        <div
          class="pre-subhead text-center"
          v-if="selectedItem && selectedItem.gozer"
        >
          <img :src="selectedItem.gozer" alt="" />
        </div>
        <div class="pre-subhead" v-if="selectedItem && !selectedItem.gozer">
          {{ selectedItem.title }}
        </div>
        <div class="pre-copy" v-if="selectedItem && !selectedItem.gozer">
          <p>
            {{ selectedItem.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PnwButton from "../../components/PnwButton.vue";
import activityConstants from "../../constants/activitiesConstants";
import routerItems from "../../constants/routerItems";

export default {
  components: { PnwButton },
  name: "QuizLanding",
  data() {
    return {
      activities: activityConstants,
      selectedActivities: [],
      selectedItem: null,
    };
  },
  methods: {
    ...mapActions({
      updateOnboardingData: "registerStore/updateOnboardingData",
    }),
    gotoNextPage() {
      this.updateOnboardingData({
        selectedActivities: this.selectedActivities,
      });
      this.$router.push({
        name: routerItems.ONBOARDING_COMPLETE,
      });
    },
    skipToNextPage() {
      this.updateOnboardingData({
        selectedActivities: [],
      });
      this.$router.push({ name: routerItems.ONBOARDING_COMPLETE });
    },
    toggleItem(item) {
      const i = this.selectedActivities.findIndex((it) => it === item.title);
      if (i >= 0) {
        this.selectedActivities.splice(i, 1);
        this.selectedItem = null;
      } else {
        this.selectedActivities.push(item.title);
        this.selectedItem = item;
      }
    },
  },
  mounted() {
    this.selectedActivities = this.onboardingData.selectedActivities || [];
    if (!this.onboardingData.inProgressing) {
      this.$router.replace({ name: routerItems.TICKET_CODE });
    }
  },
  computed: {
    ...mapGetters({
      onboardingData: "registerStore/onboardingData",
    }),
  },
};
</script>
