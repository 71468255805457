const activityConstants = [
  {
    title: "Spirit Photography",
    fullWidth: false,
    image: "/assets/images/quiz/spiritPhoto.png",
    description:
      "A type of photography whose primary goal is to capture images of ghosts and other spiritual entities, especially in ghost hunting.",
  },
  {
    title: "ESP",
    fullWidth: false,
    image: "/assets/images/quiz/esp.png",
    description:
      "Extrasensory perception, also called sixth sense, includes claimed reception of information not gained through the recognized physical senses, but sensed with the mind.",
  },
  {
    title: "Full Trance Mediums",
    fullWidth: false,
    image: "/assets/images/quiz/medium.png",
    description:
      "Mediumship is the practice of purportedly mediating communication between spirits of the dead and living human beings.",
  },
  {
    title: "Theory of Atlantis",
    fullWidth: false,
    image: "/assets/images/quiz/atlantis.png",
    description:
      "a lost civilization mentioned in Plato's dialogues Timaeus and Critias, written about 360 B.C",
  },
  {
    title: "UFOs",
    fullWidth: false,
    image: "/assets/images/quiz/ufo.png",
    description:
      "An unidentified flying object (UFO) is any perceived aerial phenomenon that cannot immediately be identified or explained.",
  },
  {
    title: "Telekinetic Movement",
    fullWidth: false,
    image: "/assets/images/quiz/tkm.png",
    description:
      "Psychokinesis, or teleinesis, is an alleged psychic ability allowing a person to influence a physical system without physical interaction.",
  },
  {
    title: "Clairvoyance",
    fullWidth: false,
    image: "/assets/images/quiz/clairvoyance.png",
    description:
      'Clairvoyance, from French clair meaning "clear" and voyance meaning "vision", is the claimed ability to gain information about an object, person, location, or physical event through extrasensory perception.',
  },
  {
    title: "The Loch Ness Monster",
    fullWidth: false,
    image: "/assets/images/quiz/nessie.png",
    description:
      "A creature in Scottish folklore that is said to inhabit Loch Ness in the Scottish Highlands. It is often described as large, long-necked, and with one or more humps protruding from the water",
  },
  {
    title: "Astral Projections",
    fullWidth: false,
    image: "/assets/images/quiz/astral.png",
    description:
      'A term used to describe an intentional out-of-body experience that assumes the existence of a soul called an "astral body" that is separate from the  physical body and capable of travelling outside it throughout the universe',
  },
  {
    title: "Mental Telepathy",
    fullWidth: false,
    image: "/assets/images/quiz/telepathy.png",
    description:
      "The vicarious transmission of information from one person to another without using any known human sensory channels or phyical interaction.",
  },
  {
    title: "The Existence of Gozer the Destructor",
    fullWidth: true,
    image: "/assets/images/quiz/existance-of-gozer.png",
    gozer: "/assets/images/quiz/gozer.png",
  },
];

export default activityConstants;
